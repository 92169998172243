@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400&display=swap');

body {
  font-family: 'Montserrat', sans-serif;
}

.nav-link.active {
  border-bottom: 4px solid;
  padding-bottom: 0;
}

a {
  color: rgb(105, 105, 105);
  text-decoration: none;
}
a:hover {
  color: rgb(51, 51, 51);
}

.section {
  /* height: 75vh; */
	/* padding-bottom: 60px; */
	scroll-margin-top: 75px;
  background-color: rgb(211, 211, 211);
  color: #212529;
  background-image: url('ico.png'), linear-gradient( rgb(252, 252, 252), rgb(228, 228, 228));
  background-repeat: repeat; 
}

.img-container{
  position:relative;
  display:inline-block;
  padding: 0;
}

.img-container img{
  display:block
 }

.img-container .overlay{
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;

  transition:opacity 500ms ease-in-out;
}

.overlay h1{
  color: #212529;
  position:absolute;
  top: 20%;
  left:50%;
  transform:translate(-20%,-20%);
  text-align: center;
}

.gallery img{
  /* width: 150px; */
  margin: 3px;
}

.gallery img:hover {
  box-shadow: 0 0 2px 1px rgba(71, 71, 71, 0.5);
}


.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > img { /* change div to reference your elements you put in <Masonry> */
  background: grey;
  margin-bottom: 30px;
}