@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400&display=swap);
body {
  font-family: 'Montserrat', sans-serif;
}

.nav-link.active {
  border-bottom: 4px solid;
  padding-bottom: 0;
}

a {
  color: rgb(105, 105, 105);
  text-decoration: none;
}
a:hover {
  color: rgb(51, 51, 51);
}

.section {
  /* height: 75vh; */
	/* padding-bottom: 60px; */
	scroll-margin-top: 75px;
  background-color: rgb(211, 211, 211);
  color: #212529;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC0AAABACAYAAACOcP4eAAAACXBIWXMAAAMTAAADEwE9ZoPHAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAABI9JREFUaIHtmkuIHFUYhc/pV/XoJMrEjCMKKhnUmGCCYFQiCKJJ1LhwEYgbF1noQl2KILhzJ1kILly6UFCDiAhu1KAoeQkiKjGikfEBeZkYmZmu+m+N97iYbhzG9K1bNT2ZWfS3afrWqdOHv/+qvvdWA0OGDBkyZKWQVF/pDKVxzn260hlKY2bTkhjS1C5XmFhIzgIYCWlWXWhJDkArpFl1oQG4IsFAQjvn9pnZB2a2ZxB2AJa3p83sJUmvADhG8rUsyyaXaCkUhF4SWZbtMLM8z/PtAGBmLzjn9i/F08xOSFo3mISLkNQ0s5POuVd7Y3me32dmJ5bi65w7LenqpSe8tPlTZnZxYVU6nc4NZqbp6enxKp6SaGZOUjukq9zTkp4FsJ/k+d7YyMjIGQC+0Whsqmg7BqABwEKiSqG7PXxLq9V6feE4yVzS+Xq9fmMVX+fcOICMpEK6SqG9908DOEDy3OJjJDve+7VVfGu12jiArFBX1ljSFQAel/RmH0lOslJo7/21AGaKdKVDO+d2A7AkSQ72keQAKoWWtB7A6SJdlfZ4QtJ7JPM+x+skK82JSa4HcKpIVyq0pLUAdgE4EJA1JP1TxrcHyXWSBlvpbmukSZJ8FpA1APgyvj0kjZE8U6Qr2x67ABwkORfQtACEjoe4huTgKt1dTTxE8uMCXeVKAxgfaHvkeb4VwIT3PhiaZBNApZ4GsIHkVJGoTKV3AjjZbrd/KZA2UCH0zMzMBIDRZrP5Y5G2TE/fD+DzCF0LFUInSTIJ4LfuGjFIVOhuP28jeShCXkeF0JI2APghRhsV2jl3K4AxScHQ3Y2WWqzvonM3kxxcaJL3AjjfarWKJvjNMr4LkXQ3Bllp7/02AIeLpoyYvwiB+RaJRlKd5J0ko1Y9sZXeAuBwzOd3X0uFzvN8M4B2o9H4JkYf+zVu9N4fidD1LsCyE6a7AHxLcjpGXBi60+lcD2BNu90+FuHXC112IvYgyS9j9YXmtVptI4DvSRZOzvFf6EZQtYDuz/4OSV/EnhNTkdsBfB1jRtJjvq+jKz03N7cdwJpWq/VJ7DkxlZ4EUGYvY67MIsB7/6ikoyT/ij2nMLSkDZKi7p8AQPICyl2Ij5H8qIQ+6mucBHA81lDSn5G+cM7dA+A2SaGV0P8ImnfnHBNJkkzFGpI8672PqrT3fh+AI+12+6dYf6C4Ik0AF0hGT4AknSMZ3Nbq6kZJ7pX0Vqx3j6LQdQBRN/we3Q2cwi2EPM/3AGCSJP32T/oSDE0yBfBzGcNupYOhJVHScyTfIHmxjD8Q8SNA8v0yhiT/kHRVSGNmu0lu8d7vLeO9bGRZttPMfg1pzOyomZW6YywrZrbJzKzfs8Asyx42M++c23K5s/VF0qiZ+dnZ2esucaxpZt+Z2dsrkS2ImU2lafrA4vEsy543szRN05tWIFYYM3sny7JnFo3dYWapmb24UrmCOOeeNLN3e+8ljZnZcTP7UNJqfOAKSLrSzH53zm1N0/RmM/vKzA4t21OrQZFl2SNm9reZnTKzl4ueWK0aJDWK/gYxZMiQIUOC/AsLbTP2CjZSeQAAAABJRU5ErkJggg==), linear-gradient( rgb(252, 252, 252), rgb(228, 228, 228));
  background-repeat: repeat; 
}

.img-container{
  position:relative;
  display:inline-block;
  padding: 0;
}

.img-container img{
  display:block
 }

.img-container .overlay{
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;

  transition:opacity 500ms ease-in-out;
}

.overlay h1{
  color: #212529;
  position:absolute;
  top: 20%;
  left:50%;
  transform:translate(-20%,-20%);
  text-align: center;
}

.gallery img{
  /* width: 150px; */
  margin: 3px;
}

.gallery img:hover {
  box-shadow: 0 0 2px 1px rgba(71, 71, 71, 0.5);
}


.my-masonry-grid { /* Not needed if autoprefixing */ /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > img { /* change div to reference your elements you put in <Masonry> */
  background: grey;
  margin-bottom: 30px;
}
